import { useEffect, useState } from "react";
import {
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd,
  IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import firebase from "firebase";
import { Redirect, Route, useLocation, Switch } from "react-router-dom";
import SignInSide from "./pages/SignInSide";
import UserProfile from "./pages/UserProfile";
import Trips from "./pages/Trips";
import Users from "./pages/Users";
import FullLoader from "./pages/FullLoader";

function App() {
  const location = useLocation();
  const currentUser = firebase.auth().currentUser;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(() => setIsLoading(false));
  }, []);
  if (isLoading) {
    return <FullLoader />;
  }
  if (
    !currentUser &&
    location.pathname !== "/login" &&
    location.pathname !== "/signup"
  ) {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  }
  if (
    currentUser &&
    (location.pathname === "/login" || location.pathname === "/signup")
  ) {
    return <Redirect to={{ pathname: "/trips" }} />;
  }
  return (
    <Switch>
      <Route path="/login">
        <SignInSide />
      </Route>
      <Route path="/signup">
        <SignInSide isSignup={true} />
      </Route>
      <Route path="/trips">
        <Trips />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/me">
        <UserProfile />
      </Route>
      <Route>
        <Redirect to={{ pathname: "/login" }} />
      </Route>
    </Switch>
  );
}

export default App;
