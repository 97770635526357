import Link from "@material-ui/core/Link";
import { Link as RouterLink, NavLink } from "react-router-dom";

export default function sLink({ isNav, ...props }) {
  return (
    <Link component={isNav ? NavLink : RouterLink} {...props}>
      {props.children}
    </Link>
  );
}
