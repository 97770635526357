import { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { IfFirebaseAuthed } from "@react-firebase/auth";
import { logout, getCurrentUser } from "../service/apiService";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
function NavBar({ title }) {
  const classes = useStyles();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.errorCode) {
        return;
      }
      setCurrentUser(res);
    });
  }, []);
  return (
    <AppBar position="relative">
      <Toolbar>
        <CameraIcon className={classes.icon} />
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
        </Typography>
        <IfFirebaseAuthed>
          <Box>
            {(currentUser.role === "ADMIN" ||
              currentUser.role === "MANAGER") && history.location.pathname !== '/users' && (
                <Button
                  color="inherit"
                  onClick={() => {
                    history.push("/users");
                  }}
                >
                  Manage Users
                </Button>
              )}
            {history.location.pathname !== '/trips' && (
              <Button
                color="inherit"
                onClick={() => {
                  history.push("/trips");
                }}
              >
                Manage Trips
              </Button>
            )}
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          </Box>
        </IfFirebaseAuthed>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
