import Box from "@material-ui/core/Box";
import Copyright from "../components/Copyright";
import CircularProgress from "@material-ui/core/CircularProgress";
function TripsPage() {
  return (
    <Box width={1} height={"100vh"}>
      <Box
        width={1}
        height={"90vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" />
      </Box>
      <Copyright />
    </Box>
  );
}

export default TripsPage;
