import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PrintIcon from "@material-ui/icons/Print";
import Copyright from "../components/Copyright";
import TripForm from "../components/TripForm";
import {
  createTrip,
  deleteTrip,
  getTrips,
  updateTrip,
  getTripsPdf,
} from "../service/apiService";
import NavBar from "../components/NavBar";
import { isFuture, formatDistanceToNow } from "date-fns";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Trips() {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState();
  const [groupedTrips, setGroupedTrips] = useState([]);
  const [filter, setFilter] = useState("all");
  const [isPrinterLoading, setIsPrinterLoading] = useState(false);

  useEffect(() => refreshTrips(), [filter]);
  const TripCard = ({ trips }) => {
    if (!trips) {
      return <Box>Nothing</Box>;
    }
    return trips.map((trip) => (
      <Grid item key={trip._id} xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.cardMedia}
            image={`https://source.unsplash.com/random?query=${trip.destination.toLowerCase()}`}
          />
          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textPrimary"
            >
              {trip.destination}
            </Typography>
            <Box>
              {trip.endDate ? (
                <Typography variant="caption" color="textSecondary">
                  {`${trip.startDate} - ${trip.endDate}`}
                </Typography>
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {trip.startDate}
                </Typography>
              )}
            </Box>
            {isFuture(new Date(trip.startDate)) && (
              <Typography variant="caption" color="textSecondary">
                {`starts in ${formatDistanceToNow(new Date(trip.startDate))}`}
              </Typography>
            )}
            <Typography variant="body1" color="textPrimary">
              {trip.comment}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setSelectedTrip(trip);
                setShowDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => deleteTrip(trip).then(() => refreshTrips())}
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </Grid>
    ));
  };
  const refreshTrips = () => {
    getTrips(filter).then((trips) => {
      setGroupedTrips(
        trips.reduce((groupedTrips, trip) => {
          if (!groupedTrips[trip.ownerId]) {
            groupedTrips[trip.ownerId] = {
              trips: [trip],
              ownerEmail: trip.ownerEmail,
            };
          } else {
            groupedTrips[trip.ownerId].trips.push(trip);
          }
          return groupedTrips;
        }, {})
      );
    });
  };
  return (
    <Box>
      {showDialog && (
        <TripForm
          open={showDialog}
          onClose={() => {
            setSelectedTrip(false);
            setShowDialog(false);
          }}
          trip={selectedTrip}
          onSubmit={(trip) => {
            if (trip._id) {
              updateTrip(trip).then(() => {
                setShowDialog(false);
                refreshTrips();
              });
              return;
            }
            createTrip(trip).then(() => {
              setShowDialog(false);
              refreshTrips();
            });
          }}
        />
      )}
      <NavBar title="Your Trips" />
      <Box component="main" minHeight="85vh">
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              View Trips
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowDialog(true)}
                  >
                    Add new Trip
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    disabled={isPrinterLoading}
                    onClick={() => {
                      setIsPrinterLoading(true);
                      getTripsPdf("month")
                        .then((pdf) => {
                          const byteCharacters = atob(pdf);
                          let byteNumbers = new Array(byteCharacters.length);
                          for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                          }
                          const byteArray = new Uint8Array(byteNumbers);
                          const file = new Blob([byteArray], {
                            type: "application/pdf;base64",
                          });
                          const fileURL = URL.createObjectURL(file);

                          // To add file name
                          let a = document.createElement("a");
                          a.href = fileURL;
                          a.download = "your-trip.pdf";
                          a.click();
                          setIsPrinterLoading(false);
                        })
                        .catch((error) => {
                          console.error(error);
                          setIsPrinterLoading(false);
                        });
                    }}
                  >
                    Print Next Month
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Filter Trips
            </InputLabel>
            <Select
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              label="Filter"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="futureOnly">Future trips only</MenuItem>
              <MenuItem value="pastOnly">Past trips only</MenuItem>
              <MenuItem value="month">Next month only</MenuItem>
            </Select>
          </FormControl>
          <Box m={2} />
          {Object.entries(groupedTrips).length === 0 ? (
            <Typography variant="h5">
              No trips yet. Please add new trip
            </Typography>
          ) : (
            Object.entries(groupedTrips).map(([key, tripInfo]) => {
              return (
                <Box key={key} mt={6}>
                  <Box mb={2}>
                    <Typography variant="h5">{tripInfo.ownerEmail}</Typography>
                  </Box>
                  <Grid container spacing={4}>
                    <TripCard trips={tripInfo.trips} />
                  </Grid>
                </Box>
              );
            })
          )}
        </Container>
      </Box>
      <Copyright />
    </Box>
  );
}
