import {
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd,
  IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Copyright from "../components/Copyright";

function UserProfile() {
  return (
    <Container maxWidth="sm">
      <Box my={4} height="85vh">
        <Typography variant="h4" component="h1" gutterBottom>
          Your Trips
        </Typography>
        {/* <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => (
            <pre style={{ height: 300, overflow: "auto" }}>
              {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
            </pre>
          )}
        </FirebaseAuthConsumer>
        <div>
          <IfFirebaseAuthedAnd
            filter={({ providerId }) => providerId !== "anonymous"}
          >
            {({ providerId }) => (
              <div>
                You are authenticated with
                {providerId}
              </div>
            )}
          </IfFirebaseAuthedAnd>
        </div> */}
      </Box>
      <Copyright />
    </Container>
  );
}

export default UserProfile;
