import axios from "axios";
import firebase from "firebase";
import { add } from "date-fns";

// const serverHost = "http://localhost:3001/api";
// eslint-disable-next-line no-undef
const serverHost = () => process.env.REACT_APP_SERVER_HOST;

const instance = axios.create({
  baseURL: serverHost(),
  timeout: 30000,
});

export const getCurrentUser = () => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance
        .get(`/users/${firebase.auth().currentUser.uid}`, {
          headers: {
            Bearer: idToken,
          },
        })
        .then((snapshot) => snapshot.data);
    });
};

export const createTrip = (trip) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.post(
        `/trips`,
        {
          ...trip,
        },
        {
          headers: {
            Bearer: idToken,
          },
        }
      );
    });
};

export const getTrips = (tripFilter) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance
        .get(`/trips`, {
          params: {
            filter: tripFilter,
            filterValue:
              tripFilter === "month"
                ? add(new Date(), { months: 1 })
                : undefined,
          },
          headers: {
            Bearer: idToken,
          },
        })
        .then((snapshot) => snapshot.data);
    });
};

export const getTripsPdf = (tripFilter) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance
        .get(`/trips/pdf`, {
          params: {
            filter: tripFilter,
            filterValue:
              tripFilter === "month"
                ? add(new Date(), { months: 1 })
                : undefined,
          },
          headers: {
            Bearer: idToken,
          },
        })
        .then((snapshot) => snapshot.data);
    });
};

export const deleteTrip = (trip) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.delete(`/trips/${trip._id}`, {
        headers: {
          Bearer: idToken,
        },
      });
    });
};

export const updateTrip = (trip) => {
  const _id = trip._id;
  delete trip._id;
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.patch(
        `/trips/${_id}`,
        { ...trip },
        {
          headers: {
            Bearer: idToken,
          },
        }
      );
    });
};

export const registerUser = (userType) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.post(
        `/auth/register`,
        {
          role: userType || "USER",
        },
        {
          headers: {
            Bearer: idToken,
          },
        }
      );
    });
};

export const getUsers = () => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance
        .get(`/users`, {
          headers: {
            Bearer: idToken,
          },
        })
        .then((snapshot) => snapshot.data);
    });
};

export const deleteUser = (user) => {
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.delete(`/users/${user.uid}`, {
        headers: {
          Bearer: idToken,
        },
      });
    });
};

export const updateUser = (user) => {
  delete user._id;
  return firebase
    .auth()
    .currentUser.getIdToken(false)
    .then((idToken) => {
      return instance.patch(
        `/users/${user.uid}`,
        { ...user },
        {
          headers: {
            Bearer: idToken,
          },
        }
      );
    });
};

export const logout = () => {
  return firebase.auth().signOut();
};
