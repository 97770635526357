import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../components/Copyright";
import { deleteUser, getUsers, updateUser } from "../service/apiService";
import NavBar from "../components/NavBar";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function Users() {
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState([]);

  useEffect(() => refreshUsers(), []);

  const refreshUsers = () => {
    getUsers().then((users) => {
      if (users.errorCode) {
        history.push("/trips");
        return;
      }
      setUsers(users);
    });
  };
  return (
    <Box>
      <NavBar title="Your Users" />
      <Box component="main">
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              View Users
            </Typography>
            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowDialog(true)}
                  >
                    Add new User
                  </Button>
                </Grid>
              </Grid>
            </div> */}
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Box m={2} />
          {users.length === 0 ? (
            <Typography variant="h5">
              No users yet. Please add new user
            </Typography>
          ) : (
            <TableContainer component="Paper">
              <Table className={classes.table} aria-label="user table">
                <TableHead>
                  <TableRow>
                    <TableCell>UID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.uid}>
                      <TableCell component="th" scope="user">
                        {user.uid}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Select
                          variant="outlined"
                          value={user.role}
                          fullWidth
                          onChange={(event) => {
                            updateUser({ ...user, role: event.target.value }).then(() => refreshUsers())
                          }}
                        >
                          <MenuItem value="USER">USER</MenuItem>
                          <MenuItem value="MANAGER">MANAGER</MenuItem>
                          <MenuItem value="ADMIN">ADMIN</MenuItem>
                        </Select></TableCell>
                      <TableCell align="right">
                        <Button
                          size="small"
                          color="primary"
                          onClick={() =>
                            deleteUser(user).then(() => refreshUsers())
                          }
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Box>
      <Copyright />
    </Box>
  );
}

export default Users;
