import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import queryString from "query-string";
import Copyright from "../components/Copyright";
import firebase from "firebase";
import Link from "../components/Link";
import { registerUser } from "../service/apiService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInSide = function ({ isSignup }) {
  const classes = useStyles();
  const location = useLocation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isSignup ? "Sign up" : "Sign In"}
          </Typography>
          <form className={classes.form} noValidate onSubmit={(e) => {
            e.preventDefault();
            console.log(email, password);
            if (!email || !password) {
              return;
            }
            setError();
            setIsLoading(true);
            if (isSignup) {
              firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                  const role = queryString.parse(location.search)?.role;
                  registerUser(role).then(() => {
                    if (role === "MANAGER") {
                      location.href = "/users";
                    }
                  });
                })
                .then(() => setIsLoading(false))
                .catch((error) => {
                  setIsLoading(false);
                  if (error.code === "auth/email-already-in-use") {
                    setError("User exists. Please sign in");
                  } else {
                    setError(error.message)
                  }
                });
              return;
            }
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((userCredential) => {
                setIsLoading(false);
                console.log(userCredential);
              })
              .catch((error) => {
                setIsLoading(false);
                if (error.code === "auth/user-not-found") {
                  setError("User not found. Please register");
                } else if (error.code === "auth/wrong-password") {
                  setError("Password is incorrect");
                } else if (error.code === "auth/too-many-requests") {
                  setError("Account is locked. Please try again in a few minutes")
                } else {
                  setError(error.message)
                }
              });
          }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.currentTarget.value)}
            />
            <Button
              disabled={isLoading}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              {isSignup ? "Sign up" : "Sign In"}
            </Button>
            {error && <Box mb={2}><Alert severity="error">{error}</Alert></Box>}
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              {isSignup ? (
                <Grid item>
                  <Link to="/login" variant="body2">
                    {"Have an account? Sign In"}
                  </Link>
                </Grid>
              ) : (
                <Grid item>
                  <Link to="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              )}
            </Grid>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default SignInSide;
