import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { TextField } from "@material-ui/core";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

export default function TripForm(props) {
  const classes = useStyles();
  const { onClose, trip: selectedTrip, onSubmit, open } = props;
  const [destination, setDestination] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [comment, setComment] = useState();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (trip) => {
    onSubmit(trip);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="trip-form"
      open={open}
    >
      <DialogTitle id="trip-form">
        {`${selectedTrip ? "Edit" : "Add"} new trip`}
      </DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate>
          <TextField
            variant="outlined"
            label="Destination"
            required
            autoFocus
            fullWidth
            value={
              destination === undefined
                ? (selectedTrip && selectedTrip.destination) || ""
                : destination
            }
            onChange={(event) => setDestination(event.target.value)}
          />
          <Box display="flex" flex={1} className={classes.input}>
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              label="Start Date"
              InputLabelProps={{
                shrink: true,
              }}
              value={
                startDate === undefined
                  ? (selectedTrip && selectedTrip.startDate) || ""
                  : startDate
              }
              required
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              label="End Date"
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={
                endDate === undefined
                  ? (selectedTrip && selectedTrip.endDate) || ""
                  : endDate
              }
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          </Box>
          <TextField
            className={classes.input}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            label="Comment"
            value={
              comment === undefined
                ? (selectedTrip && selectedTrip.comment) || ""
                : comment
            }
            onChange={(event) => setComment(event.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onSubmit({
                _id: selectedTrip ? selectedTrip._id : undefined,
                destination,
                startDate,
                endDate,
                comment,
              })
            }
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
