import env from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { config } from "./config";

env.config();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <Router>
        <App />
      </Router>
    </FirebaseAuthProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
